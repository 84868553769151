<template>
  <div
    class="position-relative"
    style="display: flex; flex-direction: column; align-items: center; width: 100%"
  >
    <!-- register logo -->
    <div class="_slot-wrapper">
      <div class="text-center mb-4">
        <h2 class="mb-1" style="font-size: 32px; font-weight: 500">
          Welcome to {{ $store.state.ecosystemName }}
        </h2>
        <span v-if="isDefaultView()" class="_welcome-subtitle mb-0 fs-2 fw-300"
          >Let's get you started! We'll analyse your business and set up AI-powered workflows for
          you</span
        >
        <span v-else class="_welcome-subtitle mb-0 fs-2 fw-300">Create your account to join</span>
      </div>

      <!-- register options -->
      <div class="_sso-wrapper mb-4">
        <button
          ref="firstElement"
          tabindex="1"
          type="button"
          class="_sso-button"
          @click.prevent="registerWithMicrosoft"
        >
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.9165 9.16797H3.24984L3.24984 2.5013L9.9165 2.5013V9.16797Z"
              fill="#FF5722"
            />
            <path d="M18.25 9.16797H11.5833V2.5013L18.25 2.5013V9.16797Z" fill="#4CAF50" />
            <path d="M18.25 17.5H11.5833V10.8333H18.25V17.5Z" fill="#FFC107" />
            <path d="M9.9165 17.5H3.24984L3.24984 10.8333H9.9165V17.5Z" fill="#03A9F4" />
          </svg>
          <span class="align-middle ml-1">Microsoft</span>
        </button>

        <button tabindex="2" type="button" class="_sso-button" @click.prevent="registerWithGoogle">
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_1286_17721"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="21"
              height="20"
            >
              <rect x="0.25" width="20" height="20" fill="#C4C4C4" />
            </mask>
            <g mask="url(#mask0_1286_17721)">
              <path
                d="M17.9305 10.1785C17.9305 9.52074 17.8771 9.04074 17.7616 8.54297H10.2505V11.5118H14.6594C14.5705 12.2496 14.0905 13.3608 13.0238 14.1074L13.0089 14.2068L15.3837 16.0466L15.5483 16.063C17.0594 14.6674 17.9305 12.6141 17.9305 10.1785Z"
                fill="#4285F4"
              />
              <path
                d="M10.2502 17.9998C12.4102 17.9998 14.2235 17.2887 15.548 16.062L13.0235 14.1064C12.348 14.5776 11.4413 14.9064 10.2502 14.9064C8.13467 14.9064 6.33913 13.5109 5.69906 11.582L5.60525 11.59L3.13581 13.5011L3.10352 13.5909C4.41906 16.2042 7.1213 17.9998 10.2502 17.9998Z"
                fill="#34A853"
              />
              <path
                d="M5.69886 11.5834C5.52998 11.0856 5.43224 10.5522 5.43224 10.0012C5.43224 9.45001 5.52998 8.9167 5.68998 8.41893L5.68551 8.31291L3.18512 6.37109L3.10332 6.41001C2.56112 7.49447 2.25 8.71227 2.25 10.0012C2.25 11.2901 2.56112 12.5078 3.10332 13.5923L5.69886 11.5834Z"
                fill="#FBBC05"
              />
              <path
                d="M10.2502 5.09332C11.7524 5.09332 12.7657 5.74221 13.3435 6.28447L15.6013 4.08001C14.2147 2.79112 12.4102 2 10.2502 2C7.1213 2 4.41906 3.79555 3.10352 6.40887L5.69018 8.41779C6.33913 6.4889 8.13467 5.09332 10.2502 5.09332Z"
                fill="#EB4335"
              />
            </g>
          </svg>
          <span class="align-middle ml-1">Google</span>
        </button>
      </div>
      <!-- register form -->
      <form @submit.prevent="submit" novalidate>
        <!-- server error -->
        <ErrorMessage v-if="status.errorMessage" v-model="status.errorMessage" class="mb-3" />
        <fieldset :disabled="status.isLoading">
          <!-- name input -->
          <div class="position-relative">
            <input
              tabindex="3"
              id="frmName"
              v-model="formData.name"
              type="text"
              autocomplete="name"
              autocapitalize="on"
              autocorrect="on"
              spellcheck="on"
              class="_input input-lg mb-3 accessibility-element"
              placeholder="Full name"
            />
          </div>
          <!-- email input -->
          <div class="position-relative">
            <input
              tabindex="4"
              id="frmEmail"
              v-model="formData.email"
              type="email"
              autocomplete="username"
              autocapitalize="none"
              autocorrect="off"
              spellcheck="off"
              class="_input input-lg mb-3 accessibility-element"
              required
              placeholder="Email address"
            />
          </div>

          <!-- password input -->
          <div class="d-flex mb-4">
            <div class="flex-fill position-relative">
              <input
                tabindex="5"
                id="frmPassword"
                v-model="formData.password"
                :type="this.passwordType"
                autocomplete="new-password"
                class="_input input-lg mb-0 password-strength accessibility-element"
                required="required"
                placeholder="Password"
              />
              <!-- password strength -->
              <PasswordStrength :value="formData.password" v-on:isValid="isPasswordValid" />
              <button type="button" class="btn end-icon" style="transition: 0.2s" @click="toggle">
                <svg
                  width="18"
                  height="14"
                  viewBox="0 0 14 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.99992 0.677795C2.15143 0.677795 0.333252 5.52628 0.333252 5.52628C0.333252 5.52628 2.15143 10.3748 6.99992 10.3748C11.8484 10.3748 13.6666 5.52628 13.6666 5.52628C13.6666 5.52628 11.8484 0.677795 6.99992 0.677795ZM6.99992 1.88992C10.1975 1.88992 11.8148 4.47603 12.3385 5.52391C11.8142 6.56452 10.1848 9.16264 6.99992 9.16264C3.80234 9.16264 2.18501 6.57653 1.66138 5.52865C2.18623 4.48804 3.81507 1.88992 6.99992 1.88992ZM6.99992 3.10204C5.66113 3.10204 4.57568 4.18749 4.57568 5.52628C4.57568 6.86507 5.66113 7.95052 6.99992 7.95052C8.33871 7.95052 9.42416 6.86507 9.42416 5.52628C9.42416 4.18749 8.33871 3.10204 6.99992 3.10204ZM6.99992 4.31416C7.66962 4.31416 8.21204 4.85658 8.21204 5.52628C8.21204 6.19598 7.66962 6.7384 6.99992 6.7384C6.33022 6.7384 5.7878 6.19598 5.7878 5.52628C5.7878 4.85658 6.33022 4.31416 6.99992 4.31416Z"
                    :fill="fillColor"
                    style="transition: 0.2s"
                  />
                </svg>
              </button>
            </div>
          </div>

          <!-- validation error -->
          <ErrorMessage
            v-if="!!formErrorMessages.length"
            v-model="formErrorMessages"
            class="mb-3"
          />

          <!-- submit button -->
          <button
            :tabindex="this.isValid() ? 6 : -1"
            type="submit"
            class="btn btn-primary btn-lg w-100 mb-3 accessibility-element"
            :style="{ backgroundColor: $store.state.buttonColor, borderRadius: '6px' }"
            :disabled="this.isValid() === false"
          >
            <Spinner v-if="status.isLoading" />
            <span v-else>Register</span>
          </button>

          <!-- footer text -->
          <p class="text-center diana-theme-text-secondary mb-0">
            Already have an account?
            <a
              tabindex="7"
              href=""
              class="diana-theme-text-primary accessibility-link"
              @click.prevent="navigateToLogin"
              @blur="$emit('last-element-blur', $refs.firstElement)"
              >Login
            </a>
          </p>
        </fieldset>
      </form>

      <div class="text-center _privacy-text">
        <p class="d-block mb-0">By signing up, you agree to our</p>
        <div>
          <a :href="$resource['terms-url']" class="_terms-link" target="_blank">Terms of Service</a>
          <span>and</span>
          <a :href="$resource['privacy-url']" class="_terms-link" target="_blank">Privacy Policy</a>
        </div>
      </div>
    </div>

    <div v-if="isDefaultView()" class="_banner-wrapper">
      <h3 style="font-weight: 500; font-size: 24px; text-align: center">
        What you'll get after registration
      </h3>
      <div class="_banner-items">
        <div class="_banner-item">
          <div class="_banner-icon">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
                fill="#23CF4E"
              />
              <g clip-path="url(#clip0_1400_27931)">
                <path
                  d="M18.1798 8.91634L10.4109 15.8754C9.93099 16.3103 10.201 17.1127 10.8459 17.1727L16.9125 17.7576L13.2755 22.8269C13.1105 23.0594 13.133 23.3818 13.3355 23.5843C13.5605 23.8093 13.9129 23.8168 14.1454 23.5993L21.9143 16.6403C22.3943 16.2053 22.1243 15.4029 21.4794 15.3429L15.4127 14.758L19.0497 9.68873C19.2147 9.45626 19.1922 9.1338 18.9897 8.93133C18.7648 8.70636 18.4123 8.69887 18.1798 8.91634Z"
                  fill="#0C0C0C"
                />
              </g>
              <defs>
                <clipPath id="clip0_1400_27931">
                  <rect width="20" height="20" fill="white" transform="translate(6 6)" />
                </clipPath>
              </defs>
            </svg>
          </div>

          <div>
            <h4 class="_banner-item-title">AI-Powered Setup</h4>
            <p class="_banner-item-subtitle">
              Instant workflow generation based on your industry and needs.
            </p>
          </div>
        </div>
        <div class="_banner-item">
          <div class="_banner-icon">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
                fill="#23CF4E"
              />
              <path
                d="M22 11.5H19.75V10C19.75 9.17275 19.0773 8.5 18.25 8.5H13.75C12.9227 8.5 12.25 9.17275 12.25 10V11.5H10C9.17275 11.5 8.5 12.1727 8.5 13V21.25C8.5 22.0772 9.17275 22.75 10 22.75H22C22.8273 22.75 23.5 22.0772 23.5 21.25V13C23.5 12.1727 22.8273 11.5 22 11.5ZM18.25 10V11.5H13.75V10H18.25ZM13 13H22V15.25H10V13H13ZM10 21.25V16.75H14.5V18.25H17.5V16.75H22L22.0007 21.25H10Z"
                fill="#0C0C0C"
              />
            </svg>
          </div>

          <div>
            <h4 class="_banner-item-title">Custom Ecosystem</h4>
            <p class="_banner-item-subtitle">Your branded digital hub where work happens.</p>
          </div>
        </div>
        <div class="_banner-item">
          <div class="_banner-icon">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
                fill="#23CF4E"
              />
              <path
                d="M14.9286 9.33203C13.3591 9.33203 12.0714 10.6197 12.0714 12.1892C12.0714 13.7587 13.3591 15.0463 14.9286 15.0463C16.4981 15.0463 17.7857 13.7587 17.7857 12.1892C17.7857 10.6197 16.4981 9.33203 14.9286 9.33203ZM14.9286 10.7606C15.726 10.7606 16.3571 11.3917 16.3571 12.1892C16.3571 12.9866 15.726 13.6177 14.9286 13.6177C14.1311 13.6177 13.5 12.9866 13.5 12.1892C13.5 11.3917 14.1311 10.7606 14.9286 10.7606ZM14.9286 17.1892C12.685 17.1892 8.5 18.2927 8.5 20.4035V22.1892H15.7656L17.1942 20.7606H9.92857V20.4035C9.92857 19.7827 12.5771 18.6177 14.9286 18.6177C16.215 18.6177 17.5876 18.9672 18.5698 19.385L19.6412 18.3136C18.2262 17.5722 16.2457 17.1892 14.9286 17.1892ZM22.2193 17.9035C22.128 17.9035 22.0364 17.9384 21.9668 18.0081L21.2525 18.7224L22.6811 20.1509L23.3954 19.4367C23.5347 19.2974 23.5347 19.0709 23.3954 18.9316L22.4718 18.0081C22.4018 17.9384 22.3106 17.9035 22.2193 17.9035ZM20.7475 19.2274L17.0714 22.9035V24.332H18.5L22.1761 20.656L20.7475 19.2274Z"
                fill="#0C0C0C"
              />
            </svg>
          </div>

          <div>
            <h4 class="_banner-item-title">Team Collaboration</h4>
            <p class="_banner-item-subtitle">
              Connect all stakeholders in one secure workspace with controlled access
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PasswordStrength from '@/components/PasswordStrength';
import querystring from 'querystring';
import { object, string } from 'yup';
import OrDivider from '../../components/OrDivider.vue';
import ErrorMessage from '@/components/ErorrMessage.vue';

const registerSchema = object({
  name: string().required('Name is required.').max(64, 'Name must be less than 64 characters.'),
  email: string()
    .required('Email is required.')
    .email('Email is invalid.')
    .max(64, 'Email must be less than 64 characters.'),
  password: string()
    .required('Password is required.')
    .min(8, 'Password must be more than 8 characters.')
    .max(64, 'Password must be less than 64 characters.'),
});

const ecosystemName = '';

export default {
  components: {
    ErrorMessage,
    PasswordStrength,
    OrDivider,
  },
  data: () => ({
    ecosystemName,
    formData: {
      name: '',
      email: '',
      password: '',
      redirectUrl: window.location.href,
    },
    status: {
      isLoading: false,
      errorMessage: '',
    },
    formErrorMessages: [],
    valid: false,
    passwordType: 'password',
    fillColor: 'var(--text-secondary)',
  }),
  computed: {
    userEmail() {
      const search = (window.location.search || '').replace(/^\?/, '');
      const query = querystring.decode(search);
      const returnUrl = query['ReturnUrl'] || '';
      const returnUrlQuery = querystring.decode(returnUrl);
      return query['email'] || returnUrlQuery['email'] || '';
    },
  },
  created() {
    if (this.userEmail) {
      this.formData.email = this.userEmail;
    }
  },
  methods: {
    isDefaultView() {
      return this.$store.state.ecosystemName === 'Rise-X';
    },
    isPasswordValid(valid) {
      this.valid = valid;
    },
    formMessages() {
      const messages = [];

      try {
        registerSchema.validateSync(
          {
            name: this.formData.name,
            email: this.formData.email,
            password: this.formData.password,
          },
          {
            abortEarly: false,
          },
        );
      } catch (e) {
        // sometimes there is only 1 error message
        if (!e.inner && e.message) {
          messages.push(e.message);
        }

        if (Array.isArray(e.inner)) {
          e.inner.forEach((e) => messages.push(e.message));
        }
      }

      return messages;
    },
    isValid() {
      return this.valid;
    },
    toggle() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
      this.fillColor =
        this.passwordType === 'text' ? this.$store.state.buttonColor : 'var(--text-secondary)';
    },
    async submit() {
      this.formErrorMessages = [];
      const formMessages = this.formMessages();
      if (formMessages.length > 0) {
        this.formErrorMessages = formMessages;
        return;
      }
      if (this.isValid() === false) return;

      try {
        this.status.isLoading = true;
        this.status.errorMessage = '';
        const formData = new FormData();
        formData.append('Name', this.formData.name);
        formData.append('Email', this.formData.email);
        formData.append('Password', this.formData.password);
        formData.append('RedirectUrl', this.formData.redirectUrl);

        const data = await this.$request({
          url: '/Account/Register',
          method: 'POST',
          data: formData,
          contentType: false,
        });

        if (data.registered)
          this.$verifyEmail({
            retryUrl: data.retryUrl,
            email: this.formData.email,
            name: this.formData.name,
          });
        else throw new Error('Unable to create an account.');
      } catch (e) {
        this.status.errorMessage = e.message;
        const data = e.xHR && e.xHR.responseJSON;
        if (Array.isArray(data)) {
          const msg = data.map((a) => a.description || '').join('\n');
          if (msg) this.status.errorMessage = msg;
        }
      } finally {
        this.status.isLoading = false;
      }
    },
    registerWithMicrosoft() {
      const query = {
        provider: 'aad',
        returnUrl:
          this.appendAnonIdToUrl(this.$store.state.loginRedirectUrl) || window.location.href,
      };
      window.location.href = `${
        this.$store.state.apiBaseUrl
      }/External/Challenge?${querystring.encode(query)}`;
    },
    registerWithGoogle() {
      const query = {
        provider: 'Google',
        returnUrl:
          this.appendAnonIdToUrl(this.$store.state.loginRedirectUrl) || window.location.href,
      };
      window.location.href = `${
        this.$store.state.apiBaseUrl
      }/External/Challenge?${querystring.encode(query)}`;
    },
    appendAnonIdToUrl(url) {
      const currentUrl = new URL(window.location.href);
      const anonId = currentUrl.searchParams.get('anonId');

      if (!anonId) {
        return url;
      }

      const updatedUrl = new URL(url);
      updatedUrl.searchParams.set('anonId', anonId);

      return updatedUrl.href;
    },
    navigateToLogin() {
      const search = (window.location.search || '').replace(/^\?/, '');
      const query = querystring.decode(search);

      query['plugin-rx-action'] = 'login';

      const newQueryString = querystring.encode(query);

      const newURL = `${window.location.pathname}?${newQueryString}`;

      window.history.replaceState({}, '', newURL);

      this.$store.commit('ovNavigation/navigate', 'login');
    },
  },
};
</script>

<style lang="scss" scoped>
._welcome-subtitle {
  color: var(--text-secondary);
}

._banner-wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding: 32px 24px;
  margin-bottom: 64px;
  max-width: 1128px;
  background-color: #f8f8f8;
  border-radius: 24px;
  @media (min-width: 900px) {
    gap: 24px;
    padding: 32px 48px;
  }
}

._banner-items {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 16px;
  @media (min-width: 900px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 48px;
  }
}

._banner-item {
  display: flex;
  gap: 16px;
}

._banner-item-title {
  margin-top: 4px;
  font-size: 18px;
  font-weight: 500;
}

._banner-item-subtitle {
  color: var(--text-secondary);
}

._banner-icon {
  width: 32px;
}
</style>
