var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "position-relative _slot-wrapper" }, [
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "_sso-wrapper mb-4" }, [
      _c(
        "button",
        {
          ref: "firstElement",
          staticClass: "_sso-button",
          attrs: { tabindex: "1", type: "button" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.loginWithMicrosoft.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "svg",
            {
              attrs: {
                width: "21",
                height: "20",
                viewBox: "0 0 21 20",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c("path", {
                attrs: {
                  d: "M9.9165 9.16797H3.24984L3.24984 2.5013L9.9165 2.5013V9.16797Z",
                  fill: "#FF5722",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M18.25 9.16797H11.5833V2.5013L18.25 2.5013V9.16797Z",
                  fill: "#4CAF50",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M18.25 17.5H11.5833V10.8333H18.25V17.5Z",
                  fill: "#FFC107",
                },
              }),
              _vm._v(" "),
              _c("path", {
                attrs: {
                  d: "M9.9165 17.5H3.24984L3.24984 10.8333H9.9165V17.5Z",
                  fill: "#03A9F4",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "align-middle ml-1" }, [
            _vm._v("Microsoft"),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "_sso-button",
          attrs: { tabindex: "2", type: "button" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.loginWithGoogle.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "svg",
            {
              attrs: {
                width: "21",
                height: "20",
                viewBox: "0 0 21 20",
                fill: "none",
                xmlns: "http://www.w3.org/2000/svg",
              },
            },
            [
              _c(
                "mask",
                {
                  staticStyle: { "mask-type": "alpha" },
                  attrs: {
                    id: "mask0_1286_17721",
                    maskUnits: "userSpaceOnUse",
                    x: "0",
                    y: "0",
                    width: "21",
                    height: "20",
                  },
                },
                [
                  _c("rect", {
                    attrs: {
                      x: "0.25",
                      width: "20",
                      height: "20",
                      fill: "#C4C4C4",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("g", { attrs: { mask: "url(#mask0_1286_17721)" } }, [
                _c("path", {
                  attrs: {
                    d: "M17.9305 10.1785C17.9305 9.52074 17.8771 9.04074 17.7616 8.54297H10.2505V11.5118H14.6594C14.5705 12.2496 14.0905 13.3608 13.0238 14.1074L13.0089 14.2068L15.3837 16.0466L15.5483 16.063C17.0594 14.6674 17.9305 12.6141 17.9305 10.1785Z",
                    fill: "#4285F4",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d: "M10.2502 17.9998C12.4102 17.9998 14.2235 17.2887 15.548 16.062L13.0235 14.1064C12.348 14.5776 11.4413 14.9064 10.2502 14.9064C8.13467 14.9064 6.33913 13.5109 5.69906 11.582L5.60525 11.59L3.13581 13.5011L3.10352 13.5909C4.41906 16.2042 7.1213 17.9998 10.2502 17.9998Z",
                    fill: "#34A853",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d: "M5.69886 11.5834C5.52998 11.0856 5.43224 10.5522 5.43224 10.0012C5.43224 9.45001 5.52998 8.9167 5.68998 8.41893L5.68551 8.31291L3.18512 6.37109L3.10332 6.41001C2.56112 7.49447 2.25 8.71227 2.25 10.0012C2.25 11.2901 2.56112 12.5078 3.10332 13.5923L5.69886 11.5834Z",
                    fill: "#FBBC05",
                  },
                }),
                _vm._v(" "),
                _c("path", {
                  attrs: {
                    d: "M10.2502 5.09332C11.7524 5.09332 12.7657 5.74221 13.3435 6.28447L15.6013 4.08001C14.2147 2.79112 12.4102 2 10.2502 2C7.1213 2 4.41906 3.79555 3.10352 6.40887L5.69018 8.41779C6.33913 6.4889 8.13467 5.09332 10.2502 5.09332Z",
                    fill: "#EB4335",
                  },
                }),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("span", { staticClass: "align-middle ml-1" }, [_vm._v("Google")]),
        ]
      ),
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submit.apply(null, arguments)
          },
        },
      },
      [
        _c(
          "fieldset",
          { attrs: { disabled: _vm.status.isLoading } },
          [
            _vm.status.successMessage
              ? _c("p", {
                  staticClass: "mb-3",
                  style: { color: _vm.$store.state.buttonColor },
                  domProps: { textContent: _vm._s(_vm.status.successMessage) },
                })
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "position-relative" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formData.Email,
                    expression: "formData.Email",
                  },
                ],
                staticClass:
                  "_input input-lg mb-0 text-left accessibility-element",
                attrs: {
                  tabindex: "3",
                  type: "email",
                  autocomplete: "email",
                  autocapitalize: "none",
                  autocorrect: "off",
                  spellcheck: "off",
                  required: "required",
                  placeholder: "Email address",
                  disabled: _vm.status.isSsoChecked,
                },
                domProps: { value: _vm.formData.Email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.formData, "Email", $event.target.value)
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.status.isSsoChecked,
                      expression: "status.isSsoChecked",
                    },
                  ],
                  staticClass: "btn end-icon",
                  staticStyle: { "margin-right": "3px" },
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.clearEmail()
                    },
                  },
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "12",
                        height: "11",
                        viewBox: "0 0 12 11",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        staticStyle: { transition: "0.2s" },
                        attrs: {
                          d: "M10.9734 2.20726L9.14007 0.373972C8.9008 0.149225 8.58727 0.0202686 8.25911 0.0116329C7.93095 0.00299726 7.61107 0.115285 7.36031 0.327137L1.33858 6.34887C1.12231 6.56697 0.98765 6.85282 0.957204 7.15846L0.669499 9.94853C0.660486 10.0465 0.673202 10.1453 0.706741 10.2378C0.74028 10.3303 0.793816 10.4143 0.863533 10.4838C0.926052 10.5458 1.0002 10.5949 1.08172 10.6282C1.16324 10.6615 1.25052 10.6783 1.33858 10.6778H1.3988L4.18887 10.4236C4.4945 10.3931 4.78036 10.2585 4.99846 10.0422L11.0202 4.02047C11.2539 3.77355 11.3802 3.44406 11.3714 3.10418C11.3627 2.76431 11.2195 2.44177 10.9734 2.20726ZM4.06843 9.08541L2.06119 9.27275L2.24184 7.26551L6.02215 3.53204L7.82867 5.33856L4.06843 9.08541ZM8.69847 4.44199L6.90534 2.64885L8.21005 1.31069L10.0366 3.13728L8.69847 4.44199Z",
                          fill: "var(--text-secondary)",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _vm.status.isSsoChecked
              ? _c("div", { staticClass: "position-relative" }, [
                  this.passwordType === "checkbox"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.Password,
                            expression: "formData.Password",
                          },
                        ],
                        staticClass:
                          "_input input-lg mt-3 mb-0 text-left accessibility-element",
                        attrs: {
                          tabindex: "4",
                          autofocus: "autofocus",
                          autocomplete: "current-password",
                          autocapitalize: "none",
                          autocorrect: "off",
                          required: "required",
                          placeholder: "Password",
                          type: "checkbox",
                        },
                        domProps: {
                          checked: Array.isArray(_vm.formData.Password)
                            ? _vm._i(_vm.formData.Password, null) > -1
                            : _vm.formData.Password,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = _vm.formData.Password,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(
                                    _vm.formData,
                                    "Password",
                                    $$a.concat([$$v])
                                  )
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    _vm.formData,
                                    "Password",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(_vm.formData, "Password", $$c)
                            }
                          },
                        },
                      })
                    : this.passwordType === "radio"
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.Password,
                            expression: "formData.Password",
                          },
                        ],
                        staticClass:
                          "_input input-lg mt-3 mb-0 text-left accessibility-element",
                        attrs: {
                          tabindex: "4",
                          autofocus: "autofocus",
                          autocomplete: "current-password",
                          autocapitalize: "none",
                          autocorrect: "off",
                          required: "required",
                          placeholder: "Password",
                          type: "radio",
                        },
                        domProps: {
                          checked: _vm._q(_vm.formData.Password, null),
                        },
                        on: {
                          change: function ($event) {
                            return _vm.$set(_vm.formData, "Password", null)
                          },
                        },
                      })
                    : _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.formData.Password,
                            expression: "formData.Password",
                          },
                        ],
                        staticClass:
                          "_input input-lg mt-3 mb-0 text-left accessibility-element",
                        attrs: {
                          tabindex: "4",
                          autofocus: "autofocus",
                          autocomplete: "current-password",
                          autocapitalize: "none",
                          autocorrect: "off",
                          required: "required",
                          placeholder: "Password",
                          type: this.passwordType,
                        },
                        domProps: { value: _vm.formData.Password },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.formData,
                              "Password",
                              $event.target.value
                            )
                          },
                        },
                      }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn end-icon",
                      attrs: { type: "button" },
                      on: { click: _vm.toggle },
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            width: "18",
                            height: "14",
                            viewBox: "0 0 14 12",
                            fill: "none",
                            xmlns: "http://www.w3.org/2000/svg",
                          },
                        },
                        [
                          _c("path", {
                            staticStyle: { transition: "0.2s" },
                            attrs: {
                              d: "M6.99992 0.677795C2.15143 0.677795 0.333252 5.52628 0.333252 5.52628C0.333252 5.52628 2.15143 10.3748 6.99992 10.3748C11.8484 10.3748 13.6666 5.52628 13.6666 5.52628C13.6666 5.52628 11.8484 0.677795 6.99992 0.677795ZM6.99992 1.88992C10.1975 1.88992 11.8148 4.47603 12.3385 5.52391C11.8142 6.56452 10.1848 9.16264 6.99992 9.16264C3.80234 9.16264 2.18501 6.57653 1.66138 5.52865C2.18623 4.48804 3.81507 1.88992 6.99992 1.88992ZM6.99992 3.10204C5.66113 3.10204 4.57568 4.18749 4.57568 5.52628C4.57568 6.86507 5.66113 7.95052 6.99992 7.95052C8.33871 7.95052 9.42416 6.86507 9.42416 5.52628C9.42416 4.18749 8.33871 3.10204 6.99992 3.10204ZM6.99992 4.31416C7.66962 4.31416 8.21204 4.85658 8.21204 5.52628C8.21204 6.19598 7.66962 6.7384 6.99992 6.7384C6.33022 6.7384 5.7878 6.19598 5.7878 5.52628C5.7878 4.85658 6.33022 4.31416 6.99992 4.31416Z",
                              fill: _vm.fillColor,
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.status.isSsoChecked
              ? _c(
                  "div",
                  { staticClass: "d-flex justify-content-end w-100 mt-2" },
                  [
                    _c(
                      "a",
                      {
                        staticClass:
                          "diana-theme-text-primary accessibility-link",
                        attrs: { tabindex: "5", href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.$store.commit(
                              "ovNavigation/navigate",
                              "forgotPassword"
                            )
                          },
                        },
                      },
                      [_vm._v("Forgot password?")]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.status.errorMessage
              ? _c("ErrorMessage", {
                  staticClass: "my-3",
                  model: {
                    value: _vm.status.errorMessage,
                    callback: function ($$v) {
                      _vm.$set(_vm.status, "errorMessage", $$v)
                    },
                    expression: "status.errorMessage",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.status.isSsoChecked
              ? _c(
                  "button",
                  {
                    staticClass:
                      "btn-lg btn-primary w-100 mb-3 mt-4 accessibility-element",
                    style: {
                      backgroundColor: _vm.$store.state.buttonColor,
                      borderRadius: "6px",
                    },
                    attrs: {
                      tabindex: _vm.formData.Password.length === 0 ? -1 : 6,
                      type: "submit",
                      disabled: _vm.formData.Password.length === 0,
                    },
                  },
                  [
                    _vm.status.isLoading
                      ? _c("Spinner")
                      : _c("span", [_vm._v("Login")]),
                  ],
                  1
                )
              : _c(
                  "button",
                  {
                    staticClass:
                      "btn-lg btn-primary w-100 mb-3 mt-4 accessibility-element",
                    style: { backgroundColor: _vm.$store.state.buttonColor },
                    attrs: {
                      tabindex: _vm.formData.Email.length === 0 ? -1 : 6,
                      type: "submit",
                      disabled: _vm.formData.Email.length === 0,
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.checkSso.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _vm.status.isLoading
                      ? _c("Spinner")
                      : _c("span", [_vm._v("Continue")]),
                  ],
                  1
                ),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c("p", { staticClass: "text-center diana-theme-text-secondary mb-0" }, [
      _vm._v("\n    Don’t have an account?\n    "),
      _c(
        "a",
        {
          staticClass: "diana-theme-text-primary accessibility-link",
          attrs: { tabindex: "7", href: "" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.navigateToRegister.apply(null, arguments)
            },
            blur: function ($event) {
              return _vm.$emit("last-element-blur", _vm.$refs.firstElement)
            },
          },
        },
        [_vm._v("Register\n    ")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text-center mb-4" }, [
      _c(
        "h2",
        {
          staticClass: "mb-1",
          staticStyle: { "font-size": "32px", "font-weight": "500" },
        },
        [_vm._v("Sign in")]
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass:
            "_welcome-subtitle mb-0 fs-2 fw-300 diana-theme-text-secondary",
        },
        [_vm._v("Continue to your ecosystem")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }