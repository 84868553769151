var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "position-relative",
      staticStyle: {
        display: "flex",
        "flex-direction": "column",
        "align-items": "center",
        width: "100%",
      },
    },
    [
      _c("div", { staticClass: "_slot-wrapper" }, [
        _c("div", { staticClass: "text-center mb-4" }, [
          _c(
            "h2",
            {
              staticClass: "mb-1",
              staticStyle: { "font-size": "32px", "font-weight": "500" },
            },
            [
              _vm._v(
                "\n        Welcome to " +
                  _vm._s(_vm.$store.state.ecosystemName) +
                  "\n      "
              ),
            ]
          ),
          _vm._v(" "),
          _vm.isDefaultView()
            ? _c(
                "span",
                { staticClass: "_welcome-subtitle mb-0 fs-2 fw-300" },
                [
                  _vm._v(
                    "Let's get you started! We'll analyse your business and set up AI-powered workflows for\n        you"
                  ),
                ]
              )
            : _c(
                "span",
                { staticClass: "_welcome-subtitle mb-0 fs-2 fw-300" },
                [_vm._v("Create your account to join")]
              ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "_sso-wrapper mb-4" }, [
          _c(
            "button",
            {
              ref: "firstElement",
              staticClass: "_sso-button",
              attrs: { tabindex: "1", type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.registerWithMicrosoft.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "21",
                    height: "20",
                    viewBox: "0 0 21 20",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      d: "M9.9165 9.16797H3.24984L3.24984 2.5013L9.9165 2.5013V9.16797Z",
                      fill: "#FF5722",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M18.25 9.16797H11.5833V2.5013L18.25 2.5013V9.16797Z",
                      fill: "#4CAF50",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M18.25 17.5H11.5833V10.8333H18.25V17.5Z",
                      fill: "#FFC107",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      d: "M9.9165 17.5H3.24984L3.24984 10.8333H9.9165V17.5Z",
                      fill: "#03A9F4",
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "align-middle ml-1" }, [
                _vm._v("Microsoft"),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "_sso-button",
              attrs: { tabindex: "2", type: "button" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.registerWithGoogle.apply(null, arguments)
                },
              },
            },
            [
              _c(
                "svg",
                {
                  attrs: {
                    width: "21",
                    height: "20",
                    viewBox: "0 0 21 20",
                    fill: "none",
                    xmlns: "http://www.w3.org/2000/svg",
                  },
                },
                [
                  _c(
                    "mask",
                    {
                      staticStyle: { "mask-type": "alpha" },
                      attrs: {
                        id: "mask0_1286_17721",
                        maskUnits: "userSpaceOnUse",
                        x: "0",
                        y: "0",
                        width: "21",
                        height: "20",
                      },
                    },
                    [
                      _c("rect", {
                        attrs: {
                          x: "0.25",
                          width: "20",
                          height: "20",
                          fill: "#C4C4C4",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c("g", { attrs: { mask: "url(#mask0_1286_17721)" } }, [
                    _c("path", {
                      attrs: {
                        d: "M17.9305 10.1785C17.9305 9.52074 17.8771 9.04074 17.7616 8.54297H10.2505V11.5118H14.6594C14.5705 12.2496 14.0905 13.3608 13.0238 14.1074L13.0089 14.2068L15.3837 16.0466L15.5483 16.063C17.0594 14.6674 17.9305 12.6141 17.9305 10.1785Z",
                        fill: "#4285F4",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M10.2502 17.9998C12.4102 17.9998 14.2235 17.2887 15.548 16.062L13.0235 14.1064C12.348 14.5776 11.4413 14.9064 10.2502 14.9064C8.13467 14.9064 6.33913 13.5109 5.69906 11.582L5.60525 11.59L3.13581 13.5011L3.10352 13.5909C4.41906 16.2042 7.1213 17.9998 10.2502 17.9998Z",
                        fill: "#34A853",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M5.69886 11.5834C5.52998 11.0856 5.43224 10.5522 5.43224 10.0012C5.43224 9.45001 5.52998 8.9167 5.68998 8.41893L5.68551 8.31291L3.18512 6.37109L3.10332 6.41001C2.56112 7.49447 2.25 8.71227 2.25 10.0012C2.25 11.2901 2.56112 12.5078 3.10332 13.5923L5.69886 11.5834Z",
                        fill: "#FBBC05",
                      },
                    }),
                    _vm._v(" "),
                    _c("path", {
                      attrs: {
                        d: "M10.2502 5.09332C11.7524 5.09332 12.7657 5.74221 13.3435 6.28447L15.6013 4.08001C14.2147 2.79112 12.4102 2 10.2502 2C7.1213 2 4.41906 3.79555 3.10352 6.40887L5.69018 8.41779C6.33913 6.4889 8.13467 5.09332 10.2502 5.09332Z",
                        fill: "#EB4335",
                      },
                    }),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "align-middle ml-1" }, [
                _vm._v("Google"),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "form",
          {
            attrs: { novalidate: "" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _vm.submit.apply(null, arguments)
              },
            },
          },
          [
            _vm.status.errorMessage
              ? _c("ErrorMessage", {
                  staticClass: "mb-3",
                  model: {
                    value: _vm.status.errorMessage,
                    callback: function ($$v) {
                      _vm.$set(_vm.status, "errorMessage", $$v)
                    },
                    expression: "status.errorMessage",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _c(
              "fieldset",
              { attrs: { disabled: _vm.status.isLoading } },
              [
                _c("div", { staticClass: "position-relative" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.name,
                        expression: "formData.name",
                      },
                    ],
                    staticClass: "_input input-lg mb-3 accessibility-element",
                    attrs: {
                      tabindex: "3",
                      id: "frmName",
                      type: "text",
                      autocomplete: "name",
                      autocapitalize: "on",
                      autocorrect: "on",
                      spellcheck: "on",
                      placeholder: "Full name",
                    },
                    domProps: { value: _vm.formData.name },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.formData, "name", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "position-relative" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.email,
                        expression: "formData.email",
                      },
                    ],
                    staticClass: "_input input-lg mb-3 accessibility-element",
                    attrs: {
                      tabindex: "4",
                      id: "frmEmail",
                      type: "email",
                      autocomplete: "username",
                      autocapitalize: "none",
                      autocorrect: "off",
                      spellcheck: "off",
                      required: "",
                      placeholder: "Email address",
                    },
                    domProps: { value: _vm.formData.email },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.formData, "email", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex mb-4" }, [
                  _c(
                    "div",
                    { staticClass: "flex-fill position-relative" },
                    [
                      this.passwordType === "checkbox"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formData.password,
                                expression: "formData.password",
                              },
                            ],
                            staticClass:
                              "_input input-lg mb-0 password-strength accessibility-element",
                            attrs: {
                              tabindex: "5",
                              id: "frmPassword",
                              autocomplete: "new-password",
                              required: "required",
                              placeholder: "Password",
                              type: "checkbox",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.formData.password)
                                ? _vm._i(_vm.formData.password, null) > -1
                                : _vm.formData.password,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = _vm.formData.password,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.formData,
                                        "password",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.formData,
                                        "password",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.formData, "password", $$c)
                                }
                              },
                            },
                          })
                        : this.passwordType === "radio"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formData.password,
                                expression: "formData.password",
                              },
                            ],
                            staticClass:
                              "_input input-lg mb-0 password-strength accessibility-element",
                            attrs: {
                              tabindex: "5",
                              id: "frmPassword",
                              autocomplete: "new-password",
                              required: "required",
                              placeholder: "Password",
                              type: "radio",
                            },
                            domProps: {
                              checked: _vm._q(_vm.formData.password, null),
                            },
                            on: {
                              change: function ($event) {
                                return _vm.$set(_vm.formData, "password", null)
                              },
                            },
                          })
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.formData.password,
                                expression: "formData.password",
                              },
                            ],
                            staticClass:
                              "_input input-lg mb-0 password-strength accessibility-element",
                            attrs: {
                              tabindex: "5",
                              id: "frmPassword",
                              autocomplete: "new-password",
                              required: "required",
                              placeholder: "Password",
                              type: this.passwordType,
                            },
                            domProps: { value: _vm.formData.password },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.formData,
                                  "password",
                                  $event.target.value
                                )
                              },
                            },
                          }),
                      _vm._v(" "),
                      _c("PasswordStrength", {
                        attrs: { value: _vm.formData.password },
                        on: { isValid: _vm.isPasswordValid },
                      }),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn end-icon",
                          staticStyle: { transition: "0.2s" },
                          attrs: { type: "button" },
                          on: { click: _vm.toggle },
                        },
                        [
                          _c(
                            "svg",
                            {
                              attrs: {
                                width: "18",
                                height: "14",
                                viewBox: "0 0 14 12",
                                fill: "none",
                                xmlns: "http://www.w3.org/2000/svg",
                              },
                            },
                            [
                              _c("path", {
                                staticStyle: { transition: "0.2s" },
                                attrs: {
                                  d: "M6.99992 0.677795C2.15143 0.677795 0.333252 5.52628 0.333252 5.52628C0.333252 5.52628 2.15143 10.3748 6.99992 10.3748C11.8484 10.3748 13.6666 5.52628 13.6666 5.52628C13.6666 5.52628 11.8484 0.677795 6.99992 0.677795ZM6.99992 1.88992C10.1975 1.88992 11.8148 4.47603 12.3385 5.52391C11.8142 6.56452 10.1848 9.16264 6.99992 9.16264C3.80234 9.16264 2.18501 6.57653 1.66138 5.52865C2.18623 4.48804 3.81507 1.88992 6.99992 1.88992ZM6.99992 3.10204C5.66113 3.10204 4.57568 4.18749 4.57568 5.52628C4.57568 6.86507 5.66113 7.95052 6.99992 7.95052C8.33871 7.95052 9.42416 6.86507 9.42416 5.52628C9.42416 4.18749 8.33871 3.10204 6.99992 3.10204ZM6.99992 4.31416C7.66962 4.31416 8.21204 4.85658 8.21204 5.52628C8.21204 6.19598 7.66962 6.7384 6.99992 6.7384C6.33022 6.7384 5.7878 6.19598 5.7878 5.52628C5.7878 4.85658 6.33022 4.31416 6.99992 4.31416Z",
                                  fill: _vm.fillColor,
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                !!_vm.formErrorMessages.length
                  ? _c("ErrorMessage", {
                      staticClass: "mb-3",
                      model: {
                        value: _vm.formErrorMessages,
                        callback: function ($$v) {
                          _vm.formErrorMessages = $$v
                        },
                        expression: "formErrorMessages",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "btn btn-primary btn-lg w-100 mb-3 accessibility-element",
                    style: {
                      backgroundColor: _vm.$store.state.buttonColor,
                      borderRadius: "6px",
                    },
                    attrs: {
                      tabindex: this.isValid() ? 6 : -1,
                      type: "submit",
                      disabled: this.isValid() === false,
                    },
                  },
                  [
                    _vm.status.isLoading
                      ? _c("Spinner")
                      : _c("span", [_vm._v("Register")]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "p",
                  {
                    staticClass: "text-center diana-theme-text-secondary mb-0",
                  },
                  [
                    _vm._v("\n          Already have an account?\n          "),
                    _c(
                      "a",
                      {
                        staticClass:
                          "diana-theme-text-primary accessibility-link",
                        attrs: { tabindex: "7", href: "" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.navigateToLogin.apply(null, arguments)
                          },
                          blur: function ($event) {
                            return _vm.$emit(
                              "last-element-blur",
                              _vm.$refs.firstElement
                            )
                          },
                        },
                      },
                      [_vm._v("Login\n          ")]
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "text-center _privacy-text" }, [
          _c("p", { staticClass: "d-block mb-0" }, [
            _vm._v("By signing up, you agree to our"),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "a",
              {
                staticClass: "_terms-link",
                attrs: { href: _vm.$resource["terms-url"], target: "_blank" },
              },
              [_vm._v("Terms of Service")]
            ),
            _vm._v(" "),
            _c("span", [_vm._v("and")]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "_terms-link",
                attrs: { href: _vm.$resource["privacy-url"], target: "_blank" },
              },
              [_vm._v("Privacy Policy")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm.isDefaultView()
        ? _c("div", { staticClass: "_banner-wrapper" }, [
            _c(
              "h3",
              {
                staticStyle: {
                  "font-weight": "500",
                  "font-size": "24px",
                  "text-align": "center",
                },
              },
              [_vm._v("\n      What you'll get after registration\n    ")]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "_banner-items" }, [
              _c("div", { staticClass: "_banner-item" }, [
                _c("div", { staticClass: "_banner-icon" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "32",
                        height: "32",
                        viewBox: "0 0 32 32",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z",
                          fill: "#23CF4E",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "g",
                        { attrs: { "clip-path": "url(#clip0_1400_27931)" } },
                        [
                          _c("path", {
                            attrs: {
                              d: "M18.1798 8.91634L10.4109 15.8754C9.93099 16.3103 10.201 17.1127 10.8459 17.1727L16.9125 17.7576L13.2755 22.8269C13.1105 23.0594 13.133 23.3818 13.3355 23.5843C13.5605 23.8093 13.9129 23.8168 14.1454 23.5993L21.9143 16.6403C22.3943 16.2053 22.1243 15.4029 21.4794 15.3429L15.4127 14.758L19.0497 9.68873C19.2147 9.45626 19.1922 9.1338 18.9897 8.93133C18.7648 8.70636 18.4123 8.69887 18.1798 8.91634Z",
                              fill: "#0C0C0C",
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c("defs", [
                        _c("clipPath", { attrs: { id: "clip0_1400_27931" } }, [
                          _c("rect", {
                            attrs: {
                              width: "20",
                              height: "20",
                              fill: "white",
                              transform: "translate(6 6)",
                            },
                          }),
                        ]),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm._m(0),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "_banner-item" }, [
                _c("div", { staticClass: "_banner-icon" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "32",
                        height: "32",
                        viewBox: "0 0 32 32",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z",
                          fill: "#23CF4E",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M22 11.5H19.75V10C19.75 9.17275 19.0773 8.5 18.25 8.5H13.75C12.9227 8.5 12.25 9.17275 12.25 10V11.5H10C9.17275 11.5 8.5 12.1727 8.5 13V21.25C8.5 22.0772 9.17275 22.75 10 22.75H22C22.8273 22.75 23.5 22.0772 23.5 21.25V13C23.5 12.1727 22.8273 11.5 22 11.5ZM18.25 10V11.5H13.75V10H18.25ZM13 13H22V15.25H10V13H13ZM10 21.25V16.75H14.5V18.25H17.5V16.75H22L22.0007 21.25H10Z",
                          fill: "#0C0C0C",
                        },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm._m(1),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "_banner-item" }, [
                _c("div", { staticClass: "_banner-icon" }, [
                  _c(
                    "svg",
                    {
                      attrs: {
                        width: "32",
                        height: "32",
                        viewBox: "0 0 32 32",
                        fill: "none",
                        xmlns: "http://www.w3.org/2000/svg",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z",
                          fill: "#23CF4E",
                        },
                      }),
                      _vm._v(" "),
                      _c("path", {
                        attrs: {
                          d: "M14.9286 9.33203C13.3591 9.33203 12.0714 10.6197 12.0714 12.1892C12.0714 13.7587 13.3591 15.0463 14.9286 15.0463C16.4981 15.0463 17.7857 13.7587 17.7857 12.1892C17.7857 10.6197 16.4981 9.33203 14.9286 9.33203ZM14.9286 10.7606C15.726 10.7606 16.3571 11.3917 16.3571 12.1892C16.3571 12.9866 15.726 13.6177 14.9286 13.6177C14.1311 13.6177 13.5 12.9866 13.5 12.1892C13.5 11.3917 14.1311 10.7606 14.9286 10.7606ZM14.9286 17.1892C12.685 17.1892 8.5 18.2927 8.5 20.4035V22.1892H15.7656L17.1942 20.7606H9.92857V20.4035C9.92857 19.7827 12.5771 18.6177 14.9286 18.6177C16.215 18.6177 17.5876 18.9672 18.5698 19.385L19.6412 18.3136C18.2262 17.5722 16.2457 17.1892 14.9286 17.1892ZM22.2193 17.9035C22.128 17.9035 22.0364 17.9384 21.9668 18.0081L21.2525 18.7224L22.6811 20.1509L23.3954 19.4367C23.5347 19.2974 23.5347 19.0709 23.3954 18.9316L22.4718 18.0081C22.4018 17.9384 22.3106 17.9035 22.2193 17.9035ZM20.7475 19.2274L17.0714 22.9035V24.332H18.5L22.1761 20.656L20.7475 19.2274Z",
                          fill: "#0C0C0C",
                        },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm._m(2),
              ]),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h4", { staticClass: "_banner-item-title" }, [
        _vm._v("AI-Powered Setup"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "_banner-item-subtitle" }, [
        _vm._v(
          "\n            Instant workflow generation based on your industry and needs.\n          "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h4", { staticClass: "_banner-item-title" }, [
        _vm._v("Custom Ecosystem"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "_banner-item-subtitle" }, [
        _vm._v("Your branded digital hub where work happens."),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("h4", { staticClass: "_banner-item-title" }, [
        _vm._v("Team Collaboration"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "_banner-item-subtitle" }, [
        _vm._v(
          "\n            Connect all stakeholders in one secure workspace with controlled access\n          "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }